// fe-build-tools handles transpiling this code into ES5
import bfauth from './utils/utils';
import {
  bindCETUnitTracking,
  initClientEventTracking,
  sendPageview
} from './client-event-tracking';

const CETData = {
  "context_page_type": "auth",
  "unit_type": "body",
  "unit_name": "main"
}

function showError(error_message) {
  bfauth_signin.err_msg.textContent = error_message;
  bfauth_signin.err_field.classList.add('signin-error');
}

function hideError () {
  bfauth_signin.err_field.classList.remove('signin-error');
}

function signinPasswordlessHandler(event) {
  event.preventDefault();

  let email = bfauth_signin.email;
  let email_redirect = bfauth_signin.email_redirect;

  if (!bfauth.isValidEmail(email.value.trim())) {
    return showError('Email is not valid.');
  }
  hideError();

  var xsrf = bfauth.getCookie('_xsrf');
  var url = '/auth/ad-track-token/hem';
  var params = 'email=' + encodeURIComponent(email.value.trim()) +
               '&_xsrf=' + encodeURIComponent(xsrf);
  var callback = function(){
    const url = new URL('/auth/csrf', window.location.href);
    url.searchParams.set('provider', 'auth0');
    url.searchParams.set('connection', 'email');
    url.searchParams.set('login_hint', email.value.trim());
    url.searchParams.set('redirect', email_redirect.value.trim());
    window.location.href = url.href;
  };

  bfauth.request(url, params, callback);
}

// assigns elements to global window object namespace
bfauth.acquireElements('bfauth_signin', {
  err_field: 'signin_form_field',
  err_msg: 'signin_error_message',
  email: 'email',
  email_redirect: 'email_redirect'
});

bfauth.formSubmitTrigger('DOMContentLoaded', 'signin_passwordless_form', signinPasswordlessHandler);
document.addEventListener('DOMContentLoaded', () => {
  initClientEventTracking();
  sendPageview();
  bindCETUnitTracking(document, CETData);
});
