import {
  createClientEvent,
  CONTEXT_PAGE_TYPES,
} from '@buzzfeed/client-event-tracking';
import {
  createClientClickHandler
} from '@buzzfeed/client-event-tracking/dist/lib/handlers';
import {
  external_link,
  pageview
} from '@buzzfeed/client-event-tracking/dist/lib/events';

const sendClientEvent = createClientEvent({ env: BZFD.Config.env, source: 'web_bf' });

export const webCommonLayerData = () => ({
  context_page_id: BZFD.Context.page.name,
  context_page_type: CONTEXT_PAGE_TYPES.AUTH,
  destination: 'buzzfeed',
  page_edition: BZFD.Context.page.localization.country,
});

export const initClientEventTracking = () => {
  /**
   * Allows other apps on the page, like `bf_header_ui`, to get context layer data
   */
  window.clientEventTracking = {
    getPageContextLayer() {
      return webCommonLayerData;
    },
    env: BZFD.Config.env,
  };
};

const createClientEventWithData = (event_config = {}) =>
  (data, ...layers) => {
    sendClientEvent(event_config, webCommonLayerData, ...layers, ...(data.layers ? data.layers : []));
  };
  
export const sendPageview = () => {
  sendClientEvent(pageview, webCommonLayerData);
};

const trackClientExternalClick = createClientEventWithData(external_link);
const attachClientExternalClickHandler = createClientClickHandler(trackClientExternalClick);

export const bindCETUnitTracking = (singleItem, data) => {
  if (!singleItem) {
    return;
  }

  singleItem.querySelectorAll('[data-pixiedust]').forEach(element => {

    if (!element.getAttribute('data-pixiedust').length) {
      return;
    }

    const elementData = JSON.parse(decodeURIComponent(element.getAttribute('data-pixiedust')));
    const concatData = Object.assign({}, data, elementData);

    if (concatData.target_content_url) {
      attachClientExternalClickHandler(element, concatData);
    }
  });
};
