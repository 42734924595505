export default {
  getCookie: function(name) {
    var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
    return r ? r[1] : undefined;
  },

  getElement: function(id) {
    return document.getElementById(id);
  },

  show: function(e) {
    if (e instanceof HTMLElement) {
      e.classList.add('xs-flex');
      e.classList.remove('xs-hide');
    }
  },

  hide: function(e) {
    if (e instanceof HTMLElement) {
      e.classList.add('xs-hide');
      e.classList.remove('xs-flex');
    }
  },

  isJsonString: function(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  },

  request(url, params, callback) {
    var xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('content-type', 'application/x-www-form-urlencoded');

    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4) {
        if (xhr.status == 500) {
          return callback({
            'state': 'error',
            'message': 'There was a problem processing this request.'
          });
        }
        var response = '';
        try {
          response = JSON.parse(xhr.responseText);
        } catch (e) {}

        return callback(response);
      }
    };

    xhr.send(params);
  },

  acquireElements: function(namespace, elements) {
    document.addEventListener('DOMContentLoaded', (event) => {
      window[namespace] = {};

      for (var k in elements) {
        window[namespace][k] = this.getElement(elements[k]);
      }
    });
  },

  formSubmitTrigger: function(evt, elem_id, handler) {
    // use a fat-arrow => inline function so it binds the `this` value
    // appropriately, and fe-build-tools will transpile it back to ES5
    document.addEventListener(evt, (event) => {
      var submit = this.getElement(elem_id);
      submit.addEventListener('submit', handler);
    });
  },

  isValidEmail: function (email) {
    var valid_email = /^([\w-]+(?:\.[\w-]+)*)(\+[\w]+)?@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return valid_email.test(email);
  }

};
